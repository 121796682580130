<template>
  <div class="rapidBiuldFigure"
       id="rapidBiuldFigure">
    <div class="title-wrapper">
      <div class="title">新建快速拼图任务</div>
      <div class="btns">
        <img :src="imgUrl"
             @click.stop="controlMap"
             alt />
        <img :src="icons.Back"
             @click.stop="GoBack"
             alt />
        <Button v-show="lookRapid"
                icon="logo-buffer"
                to="/index/rapidMap"></Button>

      </div>
    </div>
    <!--<div class="infos no-bottom" >-->
    <!--<div class="info-item">-->
    <!--<div class="title">区域面积</div>-->
    <!--<div class="data">{{config.airArea }}m<sup>2</sup></div>-->
    <!--</div>-->
    <!--<div class="info-item">-->
    <!--<div class="title">航线长度</div>-->
    <!--<div class="data">{{config.ariLineLength }}</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="infos">-->
    <!--<div class="info-item">-->
    <!--<div class="title">预计飞行时间</div>-->
    <!--<div class="data">{{formatTime(config.flyingExpectTime)}}</div>-->
    <!--</div>-->
    <!--<div class="info-item">-->
    <!--<div class="title">拍照张数</div>-->
    <!--<div class="data">{{config.photoGraphTimes}}张</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="line-settings">
      <div class="l-title">飞行设置</div>
      <div class="lines">
        <template>
          <div class="line-item">
            <div class="title">
              <pre>飞行速度 (m/s)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.speed.max"
                        :min="rangeConfig.speed.min"
                        v-model="config.speed"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.speed}}</div> -->
              <Input class="data"
                     :number="true"
                     v-model="config.speed"
                     placeholder="" />
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>飞行高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.height"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.height | formatByZero}}</div> -->
              <Input class="data"
                     :number="true"
                     v-model="config.height"
                     placeholder="" />
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>目标高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.targetHeight"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.height | formatByZero}}</div> -->
              <Input class="data"
                     :number="true"
                     v-model="config.targetHeight"
                     placeholder="" />
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>相对高度 (m)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :disabled="true"
                        :max="rangeConfig.height.max"
                        :min="rangeConfig.height.min"
                        v-model="config.relativeHeight"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <div class="data">{{config.relativeHeight}}</div>
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>航线重叠率 (%)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.percent.max"
                        :min="rangeConfig.percent.min"
                        v-model="config.courseOverlapRate"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.courseOverlapRate | formatByZero}}</div> -->
              <Input class="data"
                     :number="true"
                     v-model="config.courseOverlapRate"
                     placeholder="" />
            </div>
          </div>
          <div class="line-item">
            <div class="title">
              <pre>旁向重叠率 (%)</pre>
            </div>
            <div class="line-bottom">
              <div class="line">
                <Slider :max="rangeConfig.percent.max"
                        :min="rangeConfig.percent.min"
                        v-model="config.sideOverlapRate"
                        show-tip="never"
                        :step="1"></Slider>
              </div>
              <!-- <div class="data">{{config.sideOverlapRate | formatByZero}}</div> -->
              <Input class="data"
                     :number="true"
                     v-model="config.sideOverlapRate"
                     placeholder="" />
            </div>
          </div>
        </template>
        <div class="submit">
          <Button type="primary"
                  @click.native="submit">确定</Button>
          <!-- <Button :disabled="disabled" type="primary" @click.native="submit">确定</Button> -->
          <br>
          <!--跳转到快拼结果列表-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import slam from '@/assets/img/statistics/slam1.png';
import slamLock from '@/assets/img/statistics/slam-lock.png';
import Route from '@/assets/img/statistics/route.png';
import RouteLock from '@/assets/img/statistics/route-lock.png';
import Back from '@/assets/img/statistics/back.png';
import Delete from '@/assets/img/statistics/delete.png';
import filterMixin from '@/utils/filter.mixin.js';

export default {
  name: 'rapidBiuldFigure',
  mixins: [filterMixin],
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        slam,
        slamLock,
        Route,
        RouteLock,
        Back,
        Delete,
      },
      rangeConfig: {
        speed: {
          max: 15,
          min: 1,
        },
        height: {
          max: 500,
          min: 0,
        },
        percent: {
          max: 90,
          min: 10,
        },
        angle: {
          max: 105,
          min: 0,
        },
      },
      mapControl: {
        isMapOperateLocked: false,
        markLength: 0,
      },
      slider: 0,
      list: [
        {
          label: '自动返航',
          value: 1,
        },
        {
          label: '原地降落',
          value: 2,
        },
        {
          label: '停止在最后一个执行点',
          value: 3,
        },
      ],
      loading: false,
      lastSinglePointListLength: 0,
      singlePointList: [],
      disabled: true,
      lookRapid: false,
      lock: false,
    };
  },
  watch: {
    // info
    info: {
      deep: true,
      handler: function (val) {
        if (
          val.DroneLongitude &&
          val.DroneLatitude &&
          val.DroneLongitude !== 'NaN' &&
          val.DroneLatitude !== 'NaN' &&
          val.DroneLongitude !== '0' &&
          val.DroneLatitude !== '0'
        ) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
    config: {
      deep: true,
      handler: function (val) {},
    },
    'config.height': _.debounce(function (val) {
      // 这边更新了配置 地图那边同步下更新下航线
      // this.$emit('updateAreaAutoLine', this.config);
      this.updateRelativeHeight();
    }, 500),
    'config.targetHeight': _.debounce(function (val) {
      // 更新相对高度就好了
      this.updateRelativeHeight();
    }, 500),
  },
  computed: {
    // 2级工程
    user_info() {
      return JSON.parse(sessionStorage.getItem('user_info') || '{}');
    },
    // 任务信息
    taskInfo() {
      return JSON.parse(sessionStorage.getItem('ProjectTaskinfo') || '{}');
    },
    // 确保相对高度大于20
    isRelativeHeightSafe() {
      return true;
    },
    // 编辑任务的时候切换icon
    imgUrl() {
      if (this.mapControl.isMapOperateLocked) {
        return this.icons.slam;
      } else {
        return this.icons.slamLock;
      }
    },
  },
  created() {},
  methods: {
    //返回直播界面
    GoBack() {
      this.$_bus.$emit('RapidBackHandler');
    },
    // 开启快拼任务绘制
    controlMap() {
      if (this.lock) {
        return false;
      } else {
        this.mapControl.isMapOperateLocked =
          !this.mapControl.isMapOperateLocked;
        this.mapControl.markLength = this.config.points.length;
        this.$_bus.$emit('mapControlRapid', this.mapControl);
        this.lock = true;
      }
    },
    //所需时间
    formatTime(val) {
      return (
        (this.config.speed
          ? (this.config.ariLineLength / this.config.speed / 60).toFixed(2)
          : '0.00') + 'min'
      );
    },
    // 更新相对高度
    updateRelativeHeight() {
      this.config.relativeHeight =
        this.config.height - this.config.targetHeight;
      if (this.config.relativeHeight < 20) {
        this.$notice.error({
          title: '相对高度必须大于20m!',
        });
      }
    },
    // 提交数据
    submit() {
      this.lookRapid = true;

      console.log(this.config, this.info, this.user_info);
      let C = this.config; //配置信息
      let P = []; //矩形坐标点
      JSON.parse(sessionStorage.getItem('editPath')).forEach((item) => {
        P.push({ lng: item.lng, lat: item.lat });
      });

      console.log('submit-this.info-目标用户', this.info);
      console.log('submit-this.user_info-登录账户', this.user_info);
      let data = {
        type: 'biuldFigure',
        from_id: this.user_info.user_id,
        target_id: this.info.uid,
        task: {
          local_data: P,
          filetitle: '\u65b0\u5efa\u4efb\u52a106dfgd',
          isRTKFly: false,
          aircraftType: 0,
          adder: 55,
          figure_area: C.airArea,
          air_route_len: C.ariLineLength,
          flytime: C.flyingExpectTime,
          imgcount: C.photoGraphTimes,
          fly_speed: C.speed,
          fly_height: C.height,
          course_overlap: C.courseOverlapRate,
          next_overlap: C.sideOverlapRate,
          Course_angle: C.courseAngle,
          endtype: 1,
          relative_height: 0,
          target_height: C.targetHeight,
          yuntai_pitch: -90,
          shoot_direct: 'up',
        },
      };
      console.log('watch-新建快速拼图', data);
      this.$emit('sendSocketMsg', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.rapidBiuldFigure {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  font-size: 16px;
  color: #999;
  .title-wrapper {
    padding: 30px;
    display: flex;
    align-items: center;
    .title {
      color: #333;
      font-size: 24px;
      font-weight: bold;
      flex: 1;
    }
    .btns {
      display: flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .infos {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    .info-item {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      border-right: 1px solid #e1e1e1;
      white-space: nowrap;
      &:last-child {
        border-right: 0;
      }
      .title {
        font-weight: bold;
      }
      .data {
        font-size: 14px;
        color: #409eff;
      }
    }
  }
  .no-bottom {
    border-bottom: 0;
  }
  .line-settings {
    padding: 20px;
    .l-title {
      font-size: 30px;
      color: #666;
      margin-bottom: 20px;
    }
    .lines {
      .line-item {
        padding-bottom: 30px;
        .title {
          margin-bottom: 20px;
        }
        .point {
          margin: 20px 0;
          span {
            margin-left: 20px;
            color: #409eff;
          }
        }
      }
      .select {
        .title {
          color: #409eff;
          font-size: 16px;
        }
        .line-bottom {
          /deep/ .ivu-select-selection {
            border-top: none;
            border-left: none;
            border-right: none;
          }
          /deep/ .ivu-select-dropdown {
            padding: 10px 20px;
          }
        }
      }
      .line-bottom {
        display: flex;
        align-items: center;
        .line {
          flex: 1;
          padding-right: 60px;
        }
        .data {
          text-align: center;
          flex: 0 0 60px;
          border-bottom: 1px solid #e1e1e1;
          color: #409eff;
          font-size: 14px;
          /deep/.ivu-input {
            color: #409eff;
            border: none;
            outline: none;
            text-align: center;
          }
        }
      }
    }
    .submit {
      margin-top: 30px;
      text-align: center;
      button {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}
</style>

